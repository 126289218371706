.teacher-section .custom-datatable thead th,
.teacher-section .custom-datatable tbody td {
  padding: 5px 15px !important;
  text-align: center !important;
  margin: 0 10px !important;
  /* border: 1px solid #ccc !important; */
}
.teacher-section .custom-datatable div:nth-child(3)::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #7b809a;
  border-radius: 5px;
  background-color: #f5f5f5a6;
}
.teacher-section .custom-datatable div:nth-child(3)::-webkit-scrollbar {
  width: 12px;
  height: 7px;
  background-color: #f5f5f5a6;
}

.teacher-section .custom-datatable div:nth-child(3)::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px #7b809a;
  background-color: #7b809a;
}
