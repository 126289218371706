.page-title {
  font-size: 1.1rem;
  line-height: 1.625;
  font-weight: 700;
  letter-spacing: 0.0075em;
  opacity: 1;
  text-transform: capitalize;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(52, 71, 103);
}
.hometext {
  font-size: 0.9rem;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgba(23, 23, 23, 0.6);
  font-weight: 500;
}
.hometext svg {
  position: relative;
  top: 2px;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.01071em;
  opacity: 0.5;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.6);
  line-height: 0;
  font-weight: 400;
}
.separator {
  user-select: none;
  margin-left: 8px;
  margin-right: 8px;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
}
