body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", sans-serif !important;
}

html,
p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
div,
i,
a,
button,
input,
textarea,
label,
li {
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", sans-serif !important;
  letter-spacing: 1px;
}
.main {
  flex-grow: 1;
  padding: 0px 0px 0px 0px;
  width: 100%;
  overflow: hidden;
}

.form-card {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border: 1px solid #ccc;
}
.form-card .heading {
  font-weight: 600;
}
/* ========== input, Textarea, select filed ======== */
.custom-input-filed,
.custom-textarea {
  outline: none !important;
  border-radius: 5px;
  width: -webkit-fill-available;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  letter-spacing: 0px;
  border: 1px solid #ced4da;
  color: #344767;
  font-size: 15px;
  padding: 6px 15px;
  width: 100%;
}
.custom-validation-error {
  font-size: 14px !important;
  font-weight: 500;
  color: red;
  text-transform: capitalize;
  margin-top: 4px !important;
}
