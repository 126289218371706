.register-wrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    to right,
    rgba(39, 70, 133, 0.8) 0%,
    rgba(61, 179, 197, 0.8) 100%
  );
}
.circles {
  position: absolute;
  top: 0;
  left: 0;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  overflow: hidden;
}
.circles li {
  list-style: none;
}
.circles li svg {
  position: absolute;
  display: block;
  color: rgba(255, 255, 255, 0.2);
  animation: animate 25s linear infinite;
  bottom: -150px;
}
.circles li:nth-child(1) svg {
  left: 25%;
  font-size: 80px !important;
  animation-delay: 0s;
}
.circles li:nth-child(2) svg {
  left: 10%;
  font-size: 20px !important;
  animation-delay: 2s;
  animation-duration: 12s;
}
.circles li:nth-child(3) svg {
  left: 70%;
  font-size: 20px !important;
  animation-delay: 4s;
}
.circles li:nth-child(4) svg {
  left: 40%;
  font-size: 60px !important;
  animation-delay: 0s;
  animation-duration: 18s;
}
.circles li:nth-child(5) svg {
  left: 65%;
  font-size: 20px !important;
  animation-delay: 0s;
}
.circles li:nth-child(6) svg {
  left: 75%;
  font-size: 110px !important;
  animation-delay: 3s;
}
.circles li:nth-child(7) svg {
  left: 23%;
  font-size: 150px !important;
  animation-delay: 7s;
}
.circles li:nth-child(8) svg {
  left: 50%;
  font-size: 25px !important;
  animation-delay: 15s;
  animation-duration: 45s;
}
.circles li:nth-child(9) svg {
  left: 20%;
  font-style: 15px !important;
  animation-delay: 2s;
  animation-duration: 35s;
}
.circles li:nth-child(10) svg {
  left: 85%;
  font-size: 150px !important;
  animation-delay: 0s;
  animation-duration: 11s;
}
@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}
.register-card {
  width: 400px;
  padding: 20px;
  text-align: center;
  margin: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 20px;
  background-color: #fff;
  z-index: 1;
}
.register-card hr {
  margin-top: 10px;
}
.register-card .heading {
  font-size: 0.875rem !important;
  font-weight: 700 !important;
}
.register-card .description {
  font-size: 0.8rem !important;
  margin-bottom: 0 !important;
}
.register-card .custom-input-filed {
  width: -webkit-fill-available !important;
}
.register-card .register-link {
  font-size: 14px;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  color: #ff8d1a;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer !important;
}
.otp-input-filed {
  width: 100% !important;
}

.register-card .heading {
  font-size: 1.8rem !important;
  margin-bottom: 0px !important;
}
.register-card .description {
  font-size: 14px !important;
}
@media only screen and (max-width: 768px) {
  .register-card {
    width: 80%;
  }
}
.react-tel-input .form-control {
  height: 40px !important;
  width: 100% !important;
  font-size: 16px !important;
}
/* .otp-wapper .description:first-child {
  font-size: 15px !important;
  text-align: center;
  background-color: rgb(199, 230, 243);
  padding: 5px 10px;
  color: rgb(50, 45, 45) !important;
  border-radius: 5px;
  margin-bottom: 1rem !important;
} */
.otp-wapper {
  display: grid;
  align-items: center;
  justify-content: center;
}
.otp-wapper .description {
  margin-bottom: 1rem !important;
}
.otp-wapper div:nth-child(2) {
  align-items: center;
}
.resend-btn {
  color: #e74c3c;
  cursor: pointer;
  text-decoration: underline;
}