.custom-datatable {
  padding: 10px !important;
  background: #fff !important;
  border-radius: 10px !important;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px !important;
  border: 1px solid #ccc !important;
  font-family: "Poppins", sans-serif;
}
.custom-datatable h6 {
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 30px !important;
  margin: 0px !important;
  color: rgb(52, 71, 103);
}
.custom-datatable .tss-tlx3x1-MUIDataTableToolbar-root {
  border-bottom: 1px solid #0000001f;
}
.custom-datatable .tss-1dqj55d-MUIDataTableToolbarSelect-root {
  box-shadow: rgba(97, 97, 110, 0.2) 0px 7px 29px 0px;
}
.custom-datatable tbody td div {
  justify-content: center;
}
.custom-datatable tr td div {
  font-weight: 400;
  color: #7b809a;
  margin: 0px;
  font-size: 0.9rem;
  letter-spacing: 0px;
}
.custom-datatable th,
.custom-datatable td {
  padding: 2px !important;
  /* text-align: center !important; */
  /* width: 100% !important; */
}
.custom-datatable tr td:first-child {
  background-color: #fff !important;
}

.custom-datatable th span {
  justify-content: center;
  width: fit-content !important;
}
.custom-datatable th button {
  width: max-content;
}

.custom-datatable th div {
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  margin: 0px;
  color: #344767;
  letter-spacing: 0px;
  padding: 0px;
  margin: 0px;
}
.custom-datatable tfoot tr td {
  border: none;
}
.custom-datatable svg {
  color: #7b809a;
  font-size: 22px;
}
