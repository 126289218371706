.account-seeting-card,
.profile-tab-panel {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border: 1px solid #ccc;
}
.account-setting-card-header .heading {
  padding: 0px 0px 10px 10px !important;
  font-weight: 600;
}
.account-seeting-card div:nth-child(2) {
  border: none !important;
}
.profile-tab {
  align-items: baseline !important;
}
.profile-tab.Mui-selected {
  background-color: #ffdeda40 !important;
  color: #ff875a !important;
  border-color: #ff875a !important;
}
.profile-tabs-wrapper span:nth-child(2) {
  background-color: #ff5364 !important;
  left: 0 !important;
}
.profile-tab-panel .css-19kzrtu {
  padding: 0px !important;
}
.profile-tab-panel {
  width: 70%;
  margin: 0px 10px !important;
}
.profile-tab-panel .heading{
    font-weight: 600;
}
.personal-info {
  margin-bottom: 10px;
}
.personal-info .description {
  font-size: 13px !important;
}
.personal-info-wrapper {
  padding: 20px;
}
@media only screen and (max-width: 768px) {
  .profile-tab-panel {
    width: 100%;
  }
}
