.custom-table thead tr th {
  font-size: 0.9rem;
  font-weight: 700;
  letter-spacing: 0.00735em;
  color: rgb(52, 71, 103);
}
.custom-table tr td {
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  color: rgb(123, 128, 154);
  font-weight: 400;
}
.custom-table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.custom-table::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.custom-table::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #d62929;
}
