.booking-head {
  text-align: center;
}
.booking-head span {
  color: #1987de !important;
  font-weight: 600 !important;
}
.class-timing-btn {
  position: relative !important;
  display: inline-block !important;
  vertical-align: middle !important;
  padding: 10px 10px !important;
  width: 100% !important;
  border-radius: 5px !important;
  border: 1px solid #dedede !important;
  color: #266ad1 !important;
  background: #e9f6ff !important;
  font-size: 0.75rem !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.class-timing-btn.disabled {
  border: 1px solid #a1a0a0 !important;
  color: #f0f0f0 !important;
  background: #a1a0a0 !important;
  cursor: not-allowed;
}
.class-timing-btn.active {
  background-color: #1987de !important;
  color: #fff !important;
}
.classes-footer {
  margin-top: 1rem;
  text-align: center;
}
.classes-footer span {
  margin-top: 1rem;
  font-size: 0.8rem;
}
.selected-dt span {
  color: #fb7a27 !important;
  font-weight: 600 !important;
}

.date-card {
  width: 75px;
  height: 75px;
  background: #fff;
  border: 1px solid #e7e7e7;
  border-radius: 8px;
  display: inline-block;
  cursor: pointer;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin: 0px 10px;
  text-align: center;
}
.date-card.active {
  background: #e4efff;
  border: 1px solid #5f93e3;
}
.date-card .date-card-day {
  font-size: 1rem !important;
  font-weight: bold;
}
.date-card .date-card-month {
  font-size: 1rem !important;
  font-weight: 500;
  color: #7b809a;
}
.date-card .date-card-week {
  font-weight: bold;
  font-size: 1rem !important;
  color: #5f93e3 !important;
  margin: 5px;
}

.date-wrapper button p {
  font-size: 15px;
}

.date-wrapper button.Mui-selected p {
  color: #fff;
  transition: all ease-in-out 0.4s;
}
.date-wrapper button.Mui-selected div {
  background-color: #1987de;
  transition: all ease-in-out 0.4s;
  box-shadow: 0px 0px 10px #ccc;
  border-radius: 5px;
}

.date-wrapper span:last-child {
  display: none !important;
}
.book-class-from .heading {
  font-weight: 500;
}
.curose-btn {
  border: 1px solid #1987de !important;
  margin: 1rem !important;
  box-shadow: 0px 0px 10px #ccc;
  background: #fff !important;
}
.curose-btn.active .heading {
  color: #fff;
}

.curose-btn.active {
  background: #1987de !important;
  transition: all ease-in-out 0.4s;
  box-shadow: 0px 0px 10px #ccc;
  border-radius: 5px;
}
.class-timing-btn.disable {
  background: #dedede !important;
  color: #989595 !important;
  border: 1px solid #dedede !important;
  border-radius: 5px;
  cursor: not-allowed;
}

.error {
  font-size: 14px !important;
  color: red;
}
