.leftsidenav .MuiDrawer-paper {
  height: 94vh;
  margin-top: 4rem;
}
.leftsidenav div {
  background-color: #15304f !important;
  color: #8590a5;
}
.leftsidenav div:hover {
  color: white !important;
}
.leftsidenav .active div {
  color: white !important;
}
.leftsidenav button {
  color: #8590a5 !important;
}
.leftsidenav li,
.leftsidenav span {
  font-size: 13.3px;
  font-weight: 500;
}
.leftsidenav svg {
  font-size: 1.1rem;
  vertical-align: middle;
  transition: all 0.4s;
  opacity: 0.75;
}
.css-1tl6s6j {
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%) !important;
}

header {
  /* width: calc(100% - 65px)!important; */
  background-color: white !important;
  color: #636e75 !important;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%) !important;
}
header button {
  border-radius: 0% !important;
}
.css-to4x1n-MuiBadge-badge {
  background-color: #ff3d60 !important;
}
.searchbar,
.searchbar:hover {
  border: 1px solid #f1f5f7;
  height: 38px;
  padding-right: 15px;
  background-color: #f1f5f7;
  box-shadow: none;
  border-radius: 10px;
  color: #636e75 !important;
}
.userimg {
  height: 36px;
  width: 36px;
  background-color: #f1f5f7;
  padding: 3px;
  border-radius: 100%;
}
.dtopbar button {
  border-radius: 0% !important;
}
.title {
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
}
.dropdown ul li {
  width: 100%;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  background-color: transparent;
  border: 0;
  font-size: 14px !important;
  font-family: Arial, Helvetica, sans-serif;
}
.css-aqqxug-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper
  .MuiAvatar-root {
  margin: 0% !important;
}

/* .css-96uuyl{margin-left: 0%!important;} */

.fromdate {
  margin-top: 9px !important;
}
.fromdate input {
  padding: 10px !important;
  width: 95px;
}
