.heading {
  font-size: 1rem;
  line-height: 1.625;
  font-weight: 700;
  letter-spacing: 0.0075em;
  opacity: 1;
  text-transform: capitalize;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(52, 71, 103);
}
.description {
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(123, 128, 154);
  font-weight: 400;
}
/* custom form group */
.custom-form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.custom-form-label {
  color: #685f78;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
.communication-setting-wrapper {
  margin: 1rem 0;
}
/* custom input filed */
