.upcoming-card-wrapper .alert-title {
  background: #e1ffe7;
  color: #21b34c;
  width: calc(100% - 50px);
  margin: auto;
  text-align: center;
  display: block;
  font-weight: 700;
  padding: 0;
  border-radius: 16px 16px 0px 0px;
}
.upcoming-card {
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem,
    rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
  border-radius: 15px;
  padding: 10px;
  background: #fff;
  margin-bottom: 2rem;
}
.upcoming-card .upcoming-card-head {
  width: 50%;
}
.upcoming-card .upcoming-card-head .heading {
  color: #000;
  font-weight: 600;
  font-size: 25px;
  text-transform: capitalize;
  line-height: 2.5rem;
}
.upcoming-card .upcoming-card-head .description {
  font-size: 14px;
}
.countdown-timer {
  width: 50%;
  text-align: right;
  display: grid;
}
.number-container {
  margin: 2px;
  border-radius: 6px;
  font-size: 16px;
  color: #14758a;
  font-weight: 600;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  background: #d4f3f7;
  align-items: center;
}
.countdown-timer .number-colan {
  margin: 0px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.upcoming-class-instruction {
  background: #f5f5f5;
  border-radius: 10px;
}
.upcoming-class-instruction .icon-box {
  background: #fff;
  box-shadow: 0px 0px 10px #00000036;
  color: #000;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.upcoming-class-instruction svg {
  font-size: 20px;
}
.upcoming-class-instruction p {
  font-size: 12px;
  color: #181824;
  margin-left: 10px;
}
.upcoming-card-footer svg {
  font-size: 25px;
  margin-right: 0.5rem;
  color: #14758a;
}
.upcoming-card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.upcoming-card-footer button {
  padding: 5px 20px;
  text-align: right;
}
/* overview-section */
.overview-section .heading,
.ongoing-course-section .heading {
  font-size: 1.2rem;
  margin-bottom: 0.7rem;
  margin-left: 0.3rem;
  font-weight: 700;
}
/* custom-table */
.ongoing-course-section .custom-table {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 15px;
}
