.count-card {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: flex;
  flex-direction: column;
  position: relative; 
  min-width: 0px;
  overflow-wrap: break-word;
  background-color: rgb(255, 255, 255);
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.75rem !important;
  box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem,
    rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
  overflow: visible;
  cursor: pointer;
  padding-bottom: 0% !important;
}
.count-card-head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
}
.count-card-head svg {
  font-size: 1.3rem;
  padding: 3px;
  color: #fff;
  margin-right: 10px;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-title {
  font-size: 0.875rem;
  line-height: 1.5;
  letter-spacing: 0.02857em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(123, 128, 154);
  font-weight: 400;
}
.counting {
  margin: 0 0 0;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.00735em;
  opacity: 1;
  text-transform: none;
  vertical-align: unset;
  text-decoration: none;
  color: rgb(52, 71, 103);
}
.count-hr {
  width: 50px;
  height: 3px;
  border: none;
  margin: unset;
  text-align: left;
}
